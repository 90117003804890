<template>
  <v-card
      class="mb-1"
      style="position: relative"
      @click.stop="clickOnVote(item)"
  >
    <div class="read" v-if="item.vote_type === 'INFORMING' && item.read">
      Прочитано
    </div>
    <v-list-item class="px-2 pt-1"
                 :disabled="item.closed"
                 style="min-height: 73px"
                 :class="selected ? 'grey lighten-4' : 'white'"
    >
      <v-list-item-avatar :color="!selected ? 'grey lighten-4' : 'grey lighten-2'" size="36" class="my-1">
        <MainModal
            :main="{ component: 'Vote', title: 'Повідомлення' }"
            :button="{ icon: true,
                       buttonIconColor: getVotingType(item.vote_type, 'color'),
                       buttonIcon: item.closed ? 'mdi-lock-outline' : 'mdi-vote-outline',
                       iconSize: 36
                      }"
            :item="{
                id: item.id,
                vote_type: item.vote_type,
                theme: item.theme,
                important: item.important,
                create_date: item.create_date,
                closed: item.closed,
                close_date: item.close_date,
                text: item.text,
                buildings: item.buildings,
                options: item.options
            }"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="mb-1"
                           :class="$vuetify.breakpoint.sm ? 'text-wrap pt-2' : ''"
                           style="font-size: .95rem"
                           :style="item.closed ? 'text-decoration: line-through' : ''"
        >
          {{ item.theme }}
        </v-list-item-title>
        <v-list-item-subtitle style="font-size: .8rem" :class="$vuetify.breakpoint.sm ? 'text-wrap' : ''">
          <template v-if="item.vote_type !== 'INFORMING'">
            {{ item.create_date | formatDate }}
          </template>
          <template v-else>
            {{ item.text }}
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div>
          <template v-if="item.vote_type === 'VOTING'">
            <div style="display: flex">
              <v-icon size="22" color="success darken-1" style="margin-right: 2px">mdi-checkbox-outline</v-icon>
              {{ vote_positive }}
            </div>
            <div style="display: flex">
              <v-icon size="22" color="error lighten-2" style="margin-right: 2px">mdi-checkbox-blank-outline</v-icon>
              {{ vote_negative }}
            </div>
          </template>
          <template v-else-if="item.vote_type === 'POLL'">
            <div style="display: flex">
              <v-icon size="22" color="grey darken-1" style="margin-right: 2px">mdi-account-multiple-outline</v-icon>
              {{ all_votes }}
            </div>
          </template>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import {getVotingType} from "@/utils/icons";
import {VOTE_ITEM_READ} from "@/store/actions/vote";

export default {
  name: "VoteItem",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() {
        return {
          vote_type: '',
          theme: '',
          closed: false,
          important: false,
          create_date: null,
          vote_positive: null,
          vote_negative: null
        }
      }
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    vote_positive() {
      if (this.item.vote_type === 'VOTING') {
        if (this.item.options) {
          return (this.item.options.find(item => item.positive) || {}).votes || 0
        }
        return 0
      }
      return 0
    },
    all_votes() {
      const all_votes = this.item.options.reduce((acc, item) => acc + item.votes, 0)
      const first = this.item.options.length ? this.item.options[0] : 0
      return `${all_votes}/${first.voters}`
    },
    vote_negative() {
      if (this.item.vote_type === 'VOTING') {
        if (this.item.options) {
          return (this.item.options.find(item => !item.positive) || {}).votes || 0
        }
        return 0
      }
      return 0
    },
    selected_item() {
      return this.selected
    }
  },
  methods: {
    clickOnVote(payload) {
      if (payload.vote_type === 'INFORMING') {
        if (!payload.read) {
          this.$store.dispatch(VOTE_ITEM_READ, payload.id)
              .then(response => {
                if (response) {
                  this.$emit('selectVote', payload)
                }
              })
        } else {
          this.$emit('selectVote', payload)
        }

      } else {
        this.$emit('selectVote', payload)
      }
    },
    getChipText() {
      if (!this.item.closed) return 'В процесі'
      const positive = this.item.vote_positive || 0
      const negative = this.item.vote_negative || 0

      if (positive > negative) {
        return 'Успішно'
      }

      if (positive < negative) {
        return 'Провалено'
      }

      if (positive === negative) {
        return 'Повторно'
      }
    },
    getChipColor() {
      if (!this.item.closed) return 'grey lighten-2'
      const positive = this.item.vote_positive || 0
      const negative = this.item.vote_negative || 0

      if (positive > negative) {
        return 'success'
      }

      if (positive < negative) {
        return 'error lighten-1'
      }

      if (positive === negative) {
        return 'secondary'
      }
    },
    getVotingType
  },
}
</script>

<style scoped lang="scss">
.vote-type {
  position: absolute;
  left: 0;
  top: 0;
  background: #e6e6e6;
  padding: 2px 11px;
  font-size: 0.71rem;
  z-index: 1;
  font-weight: 500;
  border-radius: 0 !important;
}
.read {
  position: absolute;
  right: 0;
  top: 0;
  background: #4daf51;
  color: #fff3e0;
  padding: 2px 11px;
  font-size: 0.71rem;
  z-index: 1;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.bordered {
  border-right: 4px solid #a5a5a5;
}
</style>