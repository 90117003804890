var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mb-1",staticStyle:{"position":"relative"},on:{"click":function($event){$event.stopPropagation();return _vm.clickOnVote(_vm.item)}}},[(_vm.item.vote_type === 'INFORMING' && _vm.item.read)?_c('div',{staticClass:"read"},[_vm._v(" Прочитано ")]):_vm._e(),_c('v-list-item',{staticClass:"px-2 pt-1",class:_vm.selected ? 'grey lighten-4' : 'white',staticStyle:{"min-height":"73px"},attrs:{"disabled":_vm.item.closed}},[_c('v-list-item-avatar',{staticClass:"my-1",attrs:{"color":!_vm.selected ? 'grey lighten-4' : 'grey lighten-2',"size":"36"}},[_c('MainModal',{attrs:{"main":{ component: 'Vote', title: 'Повідомлення' },"button":{ icon: true,
                     buttonIconColor: _vm.getVotingType(_vm.item.vote_type, 'color'),
                     buttonIcon: _vm.item.closed ? 'mdi-lock-outline' : 'mdi-vote-outline',
                     iconSize: 36
                    },"item":{
              id: _vm.item.id,
              vote_type: _vm.item.vote_type,
              theme: _vm.item.theme,
              important: _vm.item.important,
              create_date: _vm.item.create_date,
              closed: _vm.item.closed,
              close_date: _vm.item.close_date,
              text: _vm.item.text,
              buildings: _vm.item.buildings,
              options: _vm.item.options
          }}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-1",class:_vm.$vuetify.breakpoint.sm ? 'text-wrap pt-2' : '',staticStyle:{"font-size":".95rem"},style:(_vm.item.closed ? 'text-decoration: line-through' : '')},[_vm._v(" "+_vm._s(_vm.item.theme)+" ")]),_c('v-list-item-subtitle',{class:_vm.$vuetify.breakpoint.sm ? 'text-wrap' : '',staticStyle:{"font-size":".8rem"}},[(_vm.item.vote_type !== 'INFORMING')?[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.item.create_date))+" ")]:[_vm._v(" "+_vm._s(_vm.item.text)+" ")]],2)],1),_c('v-list-item-action',[_c('div',[(_vm.item.vote_type === 'VOTING')?[_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticStyle:{"margin-right":"2px"},attrs:{"size":"22","color":"success darken-1"}},[_vm._v("mdi-checkbox-outline")]),_vm._v(" "+_vm._s(_vm.vote_positive)+" ")],1),_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticStyle:{"margin-right":"2px"},attrs:{"size":"22","color":"error lighten-2"}},[_vm._v("mdi-checkbox-blank-outline")]),_vm._v(" "+_vm._s(_vm.vote_negative)+" ")],1)]:(_vm.item.vote_type === 'POLL')?[_c('div',{staticStyle:{"display":"flex"}},[_c('v-icon',{staticStyle:{"margin-right":"2px"},attrs:{"size":"22","color":"grey darken-1"}},[_vm._v("mdi-account-multiple-outline")]),_vm._v(" "+_vm._s(_vm.all_votes)+" ")],1)]:_vm._e()],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }